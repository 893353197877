import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import React, { useRef, useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
  DirectionsService,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import {
  Grid,
  Typography,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// ** Images Import
import CompleteTrackPng from "../../../assets/img/complete-track.png";
import transportPng from "../../../assets/img/transport2.png";
import warehouseDisable from "../../../assets/img/warehouseDisable.png";
import warehouse from "../../../assets/img/warehouse.png";
import placeholderTrack from "../../../assets/img/placeholder-track.png";
// ** Map key Import
import { MAP_KEY } from "../../../component/Key/constant";
// ** Core Component Import
import CompleteShipment from "../Action/CompleteShipment";
import OverviewTable from "./OverviewTab/alert-sensorTable";
import { axiosInstance } from "../../../component/axios/axiosInstance";
import Restart from "../Action/RestartShipment";
export default function Tab({ setValue, shipmentDetails, GetshipmentDetails }) {
  const { id } = useParams();
  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const [directions, setDirections] = useState(null);
  const [originCoord, setOriginCoord] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationDetails, setLocationDetails] = useState(null);
  const [destinationCoordinates, setDestinationCoordinates] = useState(null);
  const liveTrackingData = locationDetails?.sensor?.result;
  // Api's  Function  And Use Effect Calling
  const [currentCoordinates, setCurrentCoordinates] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [originAddress, setOriginAddress] = useState(null);
  const [destinationAddress, setDestinationAddress] = useState(null);

  const [selectedMarker, setSelectedMarker] = useState(null);

  const [liveTrackingCoordinates, setLiveTrackingCoordinates] = useState(null);

  const deliveredTime = dayjs(shipmentDetails?.order?.deliveredTime).toDate();
  const CurrentDate = dayjs();
  const createdDate = dayjs(shipmentDetails?.order?.createdAt).toDate();
  const currentDate = deliveredTime ? deliveredTime : CurrentDate.toDate();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };
  const [hoveredMarker, setHoveredMarker] = useState(null);

  const handleDirectionsResponse = (response) => {
    if (response !== null) {
      setDirections(response);
    }
  };

  const mapRef = useRef(null);
  const apiUrl = `/api/sensor/fetchSensorData`;
  const params = {
    search: {},
    filter: {
      order_ref: id,
      "location.coordinates.0": { $gt: 0 },
      "location.coordinates.1": { $gt: 0 },
      gps_signal: "good",
    },
    page: "",
    limit: "",
    select: { location: 1, _id: 0, createdAt: 1, isAlert: 1 },
    startDate: new Date(dayjs(startDate ?? currentDate).startOf("day")),
    endDate: new Date(dayjs(startDate ?? currentDate).endOf("day")),
    sensorName: "gps",
    sort: { createdAt: 1 },
  };
  // Api's  Function  And Use Effect Calling
  async function GetSensorDetails() {
    try {
      const response = await axiosInstance.get(apiUrl, { params });
      setLocationDetails(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  useEffect(() => {
    if (id) {
      GetSensorDetails();
    }
  }, [id]);

  useEffect(() => {
    if (startDate) {
      GetSensorDetails();
    }
  }, [startDate]);
  useEffect(() => {
    if (shipmentDetails) {
      setOriginCoord(
        shipmentDetails?.order?.originLocation?.coordinates[0] +
          "," +
          shipmentDetails?.order?.originLocation?.coordinates[1]
      );
      setDestinationCoordinates(
        shipmentDetails?.order?.destinationLocation?.coordinates[0] +
          "," +
          shipmentDetails?.order?.destinationLocation?.coordinates[1]
      );
      setCurrentLocation({
        lat: shipmentDetails?.order?.currentLocation?.coordinates[0],
        lng: shipmentDetails?.order?.currentLocation?.coordinates[1],
      });
      setCurrentCoordinates(
        shipmentDetails?.order?.currentLocation?.coordinates[0] +
          "," +
          shipmentDetails?.order?.currentLocation?.coordinates[1]
      );

      setCurrentAddress(shipmentDetails?.order?.currentLocation);
      setOriginAddress(shipmentDetails?.order?.originLocation);
      setDestinationAddress(shipmentDetails?.order?.destinationLocation);
    }
    if (liveTrackingData) {
      setLiveTrackingCoordinates(
        Array.isArray(liveTrackingData)
          ? liveTrackingData?.map((point) => ({
              lat: point.location.coordinates[0],
              lng: point.location.coordinates[1],
            }))
          : []
      );
    }
  }, [liveTrackingData, shipmentDetails]);

  useEffect(() => {
    const apiUrlMap = "/api/location/getLocationDetails";
    const origin =
      currentCoordinates !== "undefined,undefined"
        ? currentCoordinates
        : originCoord;

    const mapBody = {
      url: `https://maps.googleapis.com/maps/api/directions/json?origin=${encodeURIComponent(
        origin
      )}&destination=${destinationCoordinates}&mode=driving&key=${MAP_KEY}&region=india`,
    };

    async function GetGoogleMapData() {
      try {
        const response = await axiosInstance.post(apiUrlMap, mapBody);
        const directionsData = response.data?.routes[0];
        setDirections(directionsData);
      } catch (error) {
        console.error("API Error:", error);
      }
    }
    if (currentCoordinates && destinationCoordinates && originCoord) {
      GetGoogleMapData();
    }
  }, [currentCoordinates, originCoord, destinationCoordinates]);

  // Event Function Calling
  // Steeper Icons
  const OriginStepIcon = ({ completed }) => {
    return (
      <img
        alt="step-icon"
        style={{ height: "38px", width: "40px" }}
        src={placeholderTrack}
      />
    );
  };
  const CurrentTrackStepIcon = ({ completed }) => {
    return (
      <img
        alt="step-icon"
        style={{ height: "40px", width: "50px" }}
        src={CompleteTrackPng}
      />
    );
  };
  const DisableCompleteTrackStepIcon = ({ completed }) => {
    return (
      <img
        alt="step-icon"
        style={{ height: "30px", width: "40px" }}
        src={warehouseDisable}
      />
    );
  };
  const CompleteTrackStepIcon = ({ completed }) => {
    return (
      <img
        alt="step-icon"
        style={{ height: "30px", width: "40px" }}
        src={warehouse}
      />
    );
  };
  // See All Button Function
  const AlertDetailsShow = () => {
    setValue(3);
  };
  const SensorDetailsShow = () => {
    setValue(4);
  };
  const handleData = (data, datatype) => {
    if (datatype == "startDate") {
      setStartDate(data);
    } else if (datatype == "endDate") {
      setEndDate(data);
    }
  };

  return (
    <>
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="h6">Shipment Details</Typography>
        </Grid>
        <Grid item>
          <Grid container>
            {/* <Grid item sx={{ mr: 2 }}>
              <Restart
                GetshipmentDetails={GetshipmentDetails}
                shipmentDetails={shipmentDetails}
              />
            </Grid> */}
            {shipmentDetails?.order?.status == 0 ? (
              ""
            ) : (
              <CompleteShipment GetshipmentDetails={GetshipmentDetails} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        mt={4}
        sx={{
          backgroundColor: "#f5f5f5",
          padding: "10px",
          borderRadius: "8px",
        }}
      >
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <Typography className="tablecell-bg">Shipment Uid</Typography>
          <Typography className="tablecrow-cell-bg mt-10px">
            {shipmentDetails?.order?.order_id}
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <Typography className="tablecell-bg">Created On</Typography>
          <Typography className="tablecrow-cell-bg mt-10px">
            {shipmentDetails?.order?.createdAt
              ? `
            ${dayjs(shipmentDetails?.order?.createdAt).format("h:mm A")},
            ${dayjs(shipmentDetails?.order?.createdAt).format("DD-MM-YYYY")}`
              : ""}
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <Typography className="tablecell-bg">Device Name</Typography>
          <Typography className="tablecrow-cell-bg mt-10px">
            {shipmentDetails?.device?.name ?? "--"}
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <Typography className="tablecell-bg">Mac Id</Typography>
          <Typography className="tablecrow-cell-bg mt-10px">
            {shipmentDetails?.device?.macId ?? "--"}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Typography className="tablecell-bg">Created by</Typography>
          <Typography className="tablecrow-cell-bg mt-10px">
            User ID : {shipmentDetails?.order?.createdBy}
          </Typography>
        </Grid>
        <Grid item lg={1} md={1} sm={6} xs={12}>
          <Typography className="tablecell-bg">Status</Typography>
          {shipmentDetails?.order?.status === 1 ? (
            <Typography className="status-ongoing">Ongoing</Typography>
          ) : (
            <Typography className="status-complete">Complete</Typography>
          )}
        </Grid>
      </Grid>

      <Grid container mt={3} justifyContent={"space-between"}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={5.82}
          p={2}
          className="overview-item"
        >
          <Grid container justifyContent="space-between">
            <Typography className="primary-color fs-20">
              <Typography className="tablecrow-cell-bg ">
                Shipment Name <br />
                <span className="tablecell-bg">
                  {shipmentDetails?.order?.name}
                </span>
                <Typography className="tablecell-bg fs-13">
                  {shipmentDetails?.order?.order_id}
                </Typography>
              </Typography>
            </Typography>
            <Typography className="primary-color fs-20">
              <img
                alt="transport"
                src={transportPng}
                style={{ height: "100px", width: "270px" }}
              />
            </Typography>
          </Grid>
          <Grid container mt={2}>
            <Box sx={{ width: "100%" }}>
              <Stepper
                activeStep={shipmentDetails?.order?.status == 1 ? 1 : 2}
                orientation="vertical"
              >
                <Step>
                  <StepLabel StepIconComponent={OriginStepIcon}>
                    {shipmentDetails?.order?.origin} <br />
                    {shipmentDetails?.order?.originLocation.coordinates[0]},
                    {shipmentDetails?.order?.originLocation.coordinates[1]}
                  </StepLabel>
                </Step>
                {shipmentDetails?.order?.currentLocation && (
                  <Step>
                    <Grid
                      container
                      className="width100"
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={12} sm={12} lg={9} md={6}>
                        <StepLabel StepIconComponent={CurrentTrackStepIcon}>
                          {shipmentDetails?.order?.currentLocation?.name}
                          <br />
                          {
                            shipmentDetails?.order?.currentLocation
                              ?.coordinates[0]
                          }{" "}
                          ,
                          {
                            shipmentDetails?.order?.currentLocation
                              ?.coordinates[1]
                          }
                        </StepLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={3} md={6}>
                        <Button
                          disabled
                          startIcon={
                            <img
                              src={transportPng}
                              style={{ height: "25px", width: "30px" }}
                            />
                          }
                          size="small"
                          className="status-complete-text"
                          sx={{ mt: 2 }}
                        >
                          Current Location
                        </Button>
                      </Grid>
                    </Grid>
                  </Step>
                )}
                <Step>
                  <StepLabel
                    StepIconComponent={
                      shipmentDetails?.order?.status == 0
                        ? CompleteTrackStepIcon
                        : DisableCompleteTrackStepIcon
                    }
                  >
                    {shipmentDetails?.order?.destinationLocation?.name} <br />
                    {
                      shipmentDetails?.order?.destinationLocation
                        ?.coordinates[0]
                    }
                    ,
                    {
                      shipmentDetails?.order?.destinationLocation
                        ?.coordinates[1]
                    }
                  </StepLabel>
                </Step>
              </Stepper>
              <Paper square elevation={0} mt={4}>
                <Typography mt={2}>
                  {shipmentDetails?.order?.status == 0
                    ? " Your shipment has been delivered"
                    : "Shipment is in progress"}
                </Typography>
              </Paper>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          key={"map"}
          lg={5.82}
          className="overview-item"
        >
          <Grid container p={1} justifyContent={"space-between"}>
            <Grid item>
              <Typography
                variant="subtitle1"
                className="tablecrow-cell-bg"
                mt={1.5}
              >
                Showing Route for
                {dayjs(startDate ?? currentDate).format("DD-MM-YYYY")}
                {/* To{" "} */}
                {/* {dayjs(endDate ?? currentDate).format("DD-MM-YYYY")} */}
              </Typography>
            </Grid>
            <Grid item sx={{ border: "1px solid #ddd", borderRadius: "4px" }}>
              <Grid container>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Select Date"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputFormat="dd/MM/yyyy"
                      value={startDate ?? currentDate}
                      onChange={(e) => {
                        handleData(e, "startDate");
                      }}
                      minDate={createdDate}
                      maxDate={currentDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{ maxWidth: "150px" }}
                          variant="filled"
                          size="small"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Start date",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                {/* <Grid item sx={{ marginLeft: "10px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="End Date"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputFormat="dd/MM/yyyy"
                      value={endDate ?? currentDate}
                      onChange={(e) => {
                        handleData(e, "endDate");
                      }}
                      minDate={startDate}
                      maxDate={currentDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{ maxWidth: "150px" }}
                          variant="filled"
                          size="small"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Start date",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <LoadScript googleMapsApiKey={MAP_KEY} ref={mapRef}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={currentLocation ? currentLocation : { lat: 0, lng: 0 }}
            >
              {liveTrackingData?.map((point, index) => (
                <Marker
                  key={index}
                  label={(index + 1).toString()}
                  onClick={() => setHoveredMarker(index)}
                  position={{
                    lat: point?.location?.coordinates[0],
                    lng: point?.location?.coordinates[1],
                  }}
                >
                  {hoveredMarker === index && (
                    <InfoWindow
                      onCloseClick={(e) => {
                        e.preventDefault();
                        setHoveredMarker(null);
                      }}
                    >
                      <div>
                        <p> position : {index + 1}</p>
                        <p>
                          Date:
                          {point?.createdAt
                            ? `
                  ${dayjs(point?.createdAt).format("h:mm A")},
                  ${dayjs(point?.createdAt).format("DD-MM-YYYY")}`
                            : ""}
                        </p>
                        <p>Timestamp: {point?.location?.name}</p>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}

              {/*===================================================================> Origin Marker  */}
              <Marker
                position={{
                  lat: shipmentDetails?.order?.originLocation?.coordinates[0],
                  lng: shipmentDetails?.order?.originLocation?.coordinates[1],
                }}
                icon={{
                  url: placeholderTrack,
                  scaledSize:
                    window.google && new window.google.maps.Size(50, 50),
                }}
                title="Origin"
                onClick={() => handleMarkerClick("origin")}
              >
                {selectedMarker === "origin" && (
                  <InfoWindow
                    position={{
                      lat: shipmentDetails?.order?.originLocation
                        ?.coordinates[0],
                      lng: shipmentDetails?.order?.originLocation
                        ?.coordinates[1],
                    }}
                    onCloseClick={(e) => {
                      e.preventDefault();
                      setSelectedMarker(null);
                    }}
                  >
                    <div>
                      <h4>Origin Location</h4>
                      <h4>
                        {`${originAddress?.coordinates[0]},  ${originAddress?.coordinates[1]}  `}
                      </h4>
                      <h4> {originAddress?.name}</h4>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
              {/*===================================================================> Polyline Show Grern Color   */}
              {directions && (
                <DirectionsRenderer
                  options={{
                    directions: directions,
                    polylineOptions: {
                      strokeColor: "green",
                      strokeOpacity: 0.8,
                      strokeWeight: 5,
                    },
                    suppressMarkers: true, // Hide the default "A" and "B" markers
                  }}
                />
              )}

              {directions && (
                <>
                  {/* Custom marker for origin */}
                  <Marker
                    position={{
                      lat:
                        directions &&
                        directions?.legs &&
                        directions?.legs[0]?.start_location.lat,
                      lng:
                        directions &&
                        directions?.legs &&
                        directions?.legs[0]?.start_location.lng,
                    }}
                    icon={{
                      url: transportPng,
                      scaledSize:
                        window.google && new window.google.maps.Size(70, 40),
                    }}
                    onClick={() => handleMarkerClick("current")}
                  >
                    {selectedMarker === "current" && (
                      <InfoWindow
                        position={{
                          lat:
                            directions &&
                            directions?.legs &&
                            directions.legs[0]?.start_location.lat,
                          lng:
                            directions &&
                            directions?.legs &&
                            directions.legs[0]?.start_location.lng,
                        }}
                        onCloseClick={() => setSelectedMarker(null)}
                      >
                        <div>
                          <h4>Current Location</h4>
                          <h4>
                            {`${currentAddress?.coordinates[0]},  ${currentAddress?.coordinates[1]}  `}
                          </h4>
                          <h4> {currentAddress?.name}</h4>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>

                  <Marker
                    position={{
                      lat:
                        directions &&
                        directions?.legs &&
                        directions.legs[0]?.end_location.lat,
                      lng:
                        directions &&
                        directions?.legs &&
                        directions.legs[0]?.end_location.lng,
                    }}
                    icon={{
                      url: warehouse,
                      scaledSize:
                        window.google && new window.google.maps.Size(40, 40),
                    }}
                    onClick={() => handleMarkerClick("destination")}
                  >
                    {selectedMarker === "destination" && (
                      <InfoWindow
                        position={{
                          lat:
                            directions &&
                            directions?.legs &&
                            directions.legs[0]?.end_location.lat,
                          lng:
                            directions &&
                            directions?.legs &&
                            directions.legs[0]?.end_location.lng,
                        }}
                        onCloseClick={() => setSelectedMarker(null)}
                      >
                        <div>
                          <h4>Destination</h4>
                          <h4>
                            {`${destinationAddress?.coordinates[0]},  ${destinationAddress?.coordinates[1]}  `}
                          </h4>
                          <h4>{destinationAddress?.name} </h4>
                        </div>
                      </InfoWindow>
                    )}{" "}
                  </Marker>
                </>
              )}

              <Polyline
                path={liveTrackingCoordinates?.map((point) => ({
                  lat: point.lat,
                  lng: point.lng,
                }))}
                options={{
                  strokeColor: "blue",
                  strokeWeight: 4,
                  strokeOpacity: 0.9,
                }}
              />
              {/* DirectionsService */}
              <DirectionsService
                options={{
                  origin: {
                    lat:
                      directions &&
                      directions?.legs &&
                      directions?.legs[0]?.start_location?.lat,
                    lng:
                      directions &&
                      directions.legs &&
                      directions?.legs[0]?.start_location?.lng,
                  },
                  destination: {
                    lat:
                      directions &&
                      directions?.legs &&
                      directions?.legs[0]?.end_location?.lat,
                    lng:
                      directions &&
                      directions.legs &&
                      directions?.legs[0]?.end_location?.lng,
                  },
                  travelMode: "DRIVING",
                }}
                position={currentLocation}
                callback={handleDirectionsResponse}
              />
            </GoogleMap>
          </LoadScript>{" "}
        </Grid>
      </Grid>

      <OverviewTable
        AlertDetailsShow={AlertDetailsShow}
        SensorDetailsShow={SensorDetailsShow}
        shipmentDetails={shipmentDetails}
      />
    </>
  );
}
